<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="70%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- kode -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Kode"
                        label-align="left"
                        :label-col="{ sm: { span: 8 }, lg: { span: 6 } , xxl: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 }, lg: { span: 24 - 6 } , xxl: { span: 24 - 4 } }"
                        required
                        name="code"
                        has-feedback>
                        <a-input
                            placeholder="Kode"
                            width="100%"
                            v-model:value="form.code"
                            :disabled="form.readOnly"
                            type="number"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- referensi -->
            <ARow class="form-row">
                <ACol :sm="{ span: 24, offset: 8 }" :lg="{ span: 24, offset: 6 }" :xxl="{ span: 24, offset: 4 }">
                    <AForm
                        class="myform-vertical"
                        layout="vertical">
                        <template v-for="(item, index) in state.references.filter(i => !i.is_deleted)" :key="index">
                            <ARow class="form-row">
                                <ACol :sm="4">
                                    <AFormItem
                                        label="Kode Referensi"
                                        label-align="left"
                                        :name="['vendorCodes', 'code']"
                                        has-feedback>
                                        <AInput
                                            size="small"
                                            type="number"
                                            v-model:value="item.code"
                                            :disabled="form.readOnly || item.id"/>
                                    </AFormItem>
                                </ACol>
                                <ACol :sm="4">
                                    <AFormItem
                                        label="Nama"
                                        label-align="left"
                                        has-feedback>
                                        <AInput
                                            size="small"
                                            v-model:value="item.name"
                                            :disabled="form.readOnly || item.id"/>
                                    </AFormItem>
                                </ACol>

                                <ACol v-if="!form.readOnly" :sm="4">
                                    <AFormItem label=" " label-align="left">
                                        <AButton
                                            @click="removeRowReference(item)"
                                            size="small"
                                            type="danger">
                                            <i class="fa fa-remove"/>
                                        </AButton>
                                    </AFormItem>
                                </ACol>
                            </ARow>
                        </template>
                    </AForm>
                    <!-- add referensi -->
                    <AFormItem
                        v-if="!form.readOnly"
                        label-align="left"
                        :wrapper-col="{ sm: { span: 24 - 4 - 8 } }"
                        :colon="false">
                        <AButton type="primary" @click="AddReference">
                            <i class="fa fa-plus"></i> Tambah Kode Referensi
                        </AButton>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- nama -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Nama Distributor Curah"
                        label-align="left"
                        :label-col="{ sm: { span: 8 }, lg: { span: 6 } , xxl: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 }, lg: { span: 24 - 6 } , xxl: { span: 24 - 4 } }"
                        required
                        name="name"
                        has-feedback>
                        <AInput
                            placeholder="name distributor curah"
                            v-model:value="form.name"
                            :disabled="form.readOnly"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- email -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Email"
                        label-align="left"
                        :label-col="{ sm: { span: 8 }, lg: { span: 6 } , xxl: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 }, lg: { span: 24 - 6 } , xxl: { span: 24 - 4 } }"
                        required
                        name="email"
                        has-feedback>
                        <AInput
                            placeholder="example@domain.com"
                            v-model:value="form.email"
                            type="email"
                            :disabled="form.readOnly"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- nomor telepon -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Nomor Telepon"
                        label-align="left"
                        :label-col="{ sm: { span: 8 }, lg: { span: 6 } , xxl: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 }, lg: { span: 24 - 6 } , xxl: { span: 24 - 4 } }"
                        required
                        name="phone"
                        has-feedback>
                        <AInput
                            addon-before="+62"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            pattern="^[1-9][0-9]*$"
                            oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                            placeholder="Ex : 81xxxxxxxxx"
                            v-model:value="form.phone"
                            :disabled="form.readOnly"
                            min="0"
                            maxlength="13"
                        />
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- alamat -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Alamat"
                        label-align="left"
                        :label-col="{ sm: { span: 8 }, lg: { span: 6 } , xxl: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 }, lg: { span: 24 - 6 } , xxl: { span: 24 - 4 } }"
                        required
                        name="address"
                        has-feedback>
                        <AInput
                            placeholder="alamat"
                            v-model:value="form.address"
                            :disabled="form.readOnly"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- pilih kabupaten/kota -->
            <ARow class="form-row">
                <ACol :sm="24">
                    <AFormItem
                        label="Pilih Kabupaten/Kota"
                        label-align="left"
                        :label-col="{ sm: { span: 8 }, lg: { span: 6 } , xxl: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 }, lg: { span: 24 - 6 } , xxl: { span: 24 - 4 } }"
                        required
                        name="wilayah_id"
                        has-feedback>
                        <FilterWilayahSemua
                            v-model:value="form.wilayah_id"
                            :disabled="form.readOnly"/>
                    </AFormItem>
                </ACol>
            </ARow>

            <ARow
                v-if="!form.readOnly"
                class="form-row"
                type="flex"
                justify="end">
                <ACol>
                    <AFormItem>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>

        <div v-if="form.errors.any()">
            <ASpace>
                <AAlert
                    v-for="(item, index) in form.errors.all()"
                    type="error"
                    :key="index"
                    :message="item.message"
                    banner
                    closable
                    @close="form.clear()" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import FilterWilayahSemua from '@/components/filter/FilterWilayahSemua'
import Form from 'vform'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import includes from 'lodash/includes'

export default defineComponent({
    components: {
        FilterWilayahSemua,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['handleOkConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            type: 11, // by default tipe distributor curah
            code: null,
            name: null,
            email: null,
            phone: null,
            address: null,
            wilayah_id: null,
        }))

        const formRef = ref();

        const state = reactive({
            title: 'Lihat Distributor Curah',
            endpoint: '/api/distributors-curah',
            data: [],
            references: [],
            reference: {
                id: null,
                code: null,
                nama: '',
            },
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                type: [
                    {
                        required: true,
                        message: 'Segment tidak boleh kosong!',
                    },
                ],
                code: [
                    {
                        required: true,
                        message: 'Kode tidak boleh kosong!',
                    },
                ],
                vendorCodes: {
                    code: [
                        {
                            required: true,
                            message: 'Kode tidak boleh kosong!',
                        },
                    ],
                },
                name: [
                    {
                        required: true,
                        message: 'Nama distributor tidak boleh kosong!',
                    },
                ],
                email: [
                    {
                        required: true,
                        message: 'Email tidak boleh kosong!',
                    },
                    {
                        type: 'email',
                        message: 'Format email salah',
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: 'Nomor Telepon tidak boleh kosong!',
                    },
                    {
                        max: 12,
                        min: 0,
                        message: 'Maximal nomor telepon 12 angka',
                    },
                    {
                        pattern: /^[0-9]*$/,
                        message: 'Nomor Telepon tidak boleh memuat karakter selain angka',
                    },
                ],
                address: [
                    {
                        required: true,
                        message: 'Alamat tidak boleh kosong!',
                    },
                ],
                wilayah_id: [
                    {
                        required: true,
                        message: 'Kabupaten/Kota tidak boleh kosong!',
                    },
                ],
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const removeRowReference = (item) => {
            const index = state.references.indexOf(item)

            if (item.id === null) {
                state.references.splice(index, 1)
            } else {
                item.is_deleted = true
            }
        }

        const AddReference = () => {
            if (state.references.filter(v => v.code === null).length > 0) {
                message.warning('Pastikan kode referensi terisi!')
                return
            }
            
            state.references.push(Object.assign({}, state.reference))
        }

        const handleOk = async () => {

            // validation form
            await formRef.value.validate()
                .catch(() => {});

            if (!form.id) {
                form.post(state.endpoint)
                    .then(({ data }) => {
                        const promises = []
                        state.references.forEach(c => {
                            c.vendor_id = data.id
                            const post = apiClient
                                .post(`${state.endpoint}/${data.id}/codes`, c)
                            promises.push(post)
                        })
                        Promise.all(promises).catch(() =>
                            message.warning('Gagal simpan kode referensi!, silakan cek data distributor.'),
                        )

                        message.success('Berhasil menyimpan')

                        handleModalCancel()
                        emit('success', data)
                    })
            } else {
                form.put(`${state.endpoint}/${form.id}`)
                    .then(({ data }) => {
                        const promises = []
                        state.references.filter(c => c.id === null)
                            .forEach(c => {
                                c.vendor_id = data.id
                                const post = apiClient
                                    .post(`${state.endpoint}/${data.id}/codes`, c)
                                promises.push(post)
                            })

                        state.references.filter(c => c.is_deleted)
                            .forEach(c => {
                                c.vendor_id = data.id
                                const _delete = apiClient
                                    .delete(`${state.endpoint}/${data.id}/codes/${c.id}`)
                                promises.push(_delete)
                            })
                        
                        Promise.all(promises).catch(() =>
                            message.warning('Gagal simpan kode referensi!, silakan cek data distributor.'),
                        )

                        message.success('Berhasil memperbarui')

                        handleModalCancel()
                        emit('success', data)
                    })
            }

        }

        // fetch by id
        const fetchByID = (id) => {
            apiClient
                .get(`${state.endpoint}/${id}`)
                .then(({ data }) => {
                    form.code = data.code
                    form.name = data.name
                    form.email = data.email
                    form.phone = data.phone
                    form.address = data.address
                    form.wilayah_id = data.wilayah_id

                    apiClient
                        .get(`${state.endpoint}/${id}/codes`)
                        .then(({ data }) => {
                            state.references = Object.assign([], data.items)
                        })
                        .catch(error => console.error(error))
                })
                .catch(error => console.error({ failure: error }))
        }

        // handle func vue
        onMounted(() => {
            if (!form.id) {
                form.reset()
            } else {
                fetchByID(props.item.id)
            }

            form.readOnly = props.item.readOnly

            if (!props.item.readOnly) {
                state.title = `${!form.id ? 'Tambah' : 'Edit'} Distributor Curah`
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            removeRowReference,
            AddReference,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
